import useSWR from 'swr';

export function useRawCryptoValue({ ...swrOptions }) {
  const { data, error, isValidating } = useSWR('/api/satsgoal-current', {
    ...swrOptions,
    errorRetryCount: 10,
    errorRetryInterval: 200,
    refreshInterval: 300_000,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return { data, error, isValidating };
}

export default function useCryptoValue({ ...swrOptions } = {}) {
  const { data, error, isValidating } = useRawCryptoValue({
    ...swrOptions,
  });
  return { data, error, isValidating };
}
