import { CardParagraph, H4 } from 'components/atoms/Text';

import { BTCTextDivider } from 'components/atoms/Dividers';
import { BTCValueCard } from 'components/atoms/Cards';
import NextImage from "next/legacy/image";
import { SATOSHI_FACTOR } from 'utils/convertBtcSats';
import bitcoinLogo from 'images/bitcoinLogo.svg';
import { colorFromValue } from 'utils/utils';
import { toCurrencyString } from 'utils/currency';
import { useCalculation } from 'components/content/calculator/calculationContext';
import useCryptoValue from 'utils/useCryptoValue';
import styled from 'styled-components';
import { H3 } from '../../atoms/Text';
import { useFormState } from 'react-final-form';

const Heading = styled.strong`
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  padding: ${({ $padding }) => ($padding ? $padding : 'initial')};
  margin: initial;
  margin-bottom: initial;
  color: ${({ theme, $brand }) =>
    $brand ? theme.colors.primary : theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes[5]};
`;

const BitcoinValueBox = () => {
  const { values: { currency } } = useFormState();
  const { data, error, isValidating } = useCryptoValue();
  const btcInCurrency = currency && data && data[currency]
  const { isSatoshis } = useCalculation();
  const adjustedValue =
    data && Number(isSatoshis ? btcInCurrency / SATOSHI_FACTOR : btcInCurrency);

  return (
    <>
      <BTCValueCard>
        <NextImage
          src={bitcoinLogo}
          alt="Bitcoin Logo"
          height={64}
          width={64}
          layout="fixed"
          priority
        />
        <BTCTextDivider>
          <Heading>{isSatoshis ? 'Satoshi' : `Bitcoin`}</Heading>
          <CardParagraph>
            {!data && !error && isValidating && `Loading...`}
            {data &&
              toCurrencyString(adjustedValue, currency, {
                minimumFractionDigits: isSatoshis ? 8 : 0,
                maximumFractionDigits: isSatoshis ? 8 : 0,
              })}
          </CardParagraph>
        </BTCTextDivider>
        <H3 $color={colorFromValue(data?.changePercent24Hr)}>
          {data &&
            (data.changePercent24Hr >= 0 ? '+' : '') +
              Number(data.changePercent24Hr).toFixed(2) +
              '%'}
        </H3>
      </BTCValueCard>
    </>
  );
};

export default BitcoinValueBox;
